import * as React from 'react';
import { debounce } from '@wix/editor-elements-common-utils';
import { useAsyncRef } from '../../../../providers/useAsyncRef';
import { classes } from '../style/VideoPlayer.st.css';
import { useDidMount } from '../../../../providers/useDidMount';
import { useChangedEffect } from '../../../../providers/useChangedEffect';
import { useResizeObserver } from '../../../../providers/useResizeObserver';
import {
  getSDK,
  facebookSDK,
  IFacebookSdk,
} from '../../../../providers/ScriptLoader';
import { TestIds } from '../constants';
import useProgress, { IProgress } from './useProgress';
import {
  IPlayer,
  IFacebookPlayer,
  IPlayerProps,
  IPlayerHandles,
  FacebookEvent,
} from './players.types';

const RESIZE_DEBOUNCE_INTERVAL = 100;
const EVENTS = {
  PLAY: 'startedPlaying' as FacebookEvent,
  PAUSE: 'paused' as FacebookEvent,
  END: 'finishedPlaying' as FacebookEvent,
  ERROR: 'error' as FacebookEvent,
};

const sdkParams = {
  appId: '',
  language: 'en_US',
  version: 'v2.4',
};

const usePlayer = (
  container: React.MutableRefObject<HTMLDivElement | null>,
  config: any,
): [() => Promise<IFacebookPlayer>, () => IFacebookPlayer | void] => {
  const [waitForPlayer, getPlayer, setPlayer] = useAsyncRef<IFacebookPlayer>();

  useDidMount(() => {
    const waitForSDK = getSDK<IFacebookSdk>(facebookSDK, sdkParams);

    waitForSDK
      .then(Facebook => {
        initPlayer(Facebook, setPlayer, config, container);
      })
      .catch(e => {
        // TODO - handle errors properly
        throw e;
      });

    return () => {
      waitForSDK
        .then((Facebook: any) => {
          Facebook.Event.unsubscribe('xfbml.ready', handleReady);
          Facebook.Event.unsubscribe('iframeplugin:create', setAllowAttribute);
        })
        .catch(e => {
          // TODO - handle errors properly
          throw e;
        });
    };
  });

  return [waitForPlayer, getPlayer];
};

const initPlayer = (FB: any, setPlayer: any, config: any, container: any) => {
  FB.init({
    appId: '',
    xfbml: true,
    version: 'v2.5',
  });

  FB.Event.subscribe('xfbml.ready', (msg: any) => {
    handleReady({ msg, setPlayer, config });
  });
  FB.Event.subscribe('iframeplugin:create', () => setAllowAttribute(container));
};

const handleReady = ({
  msg,
  setPlayer,
  config,
}: {
  msg: any;
  setPlayer: any;
  config: any;
}) => {
  const { playerId } = config;

  if (msg.type === 'video' && msg.id === playerId) {
    setPlayer(msg.instance);
  }
};

const setAllowAttribute = (containerRef: any) => {
  if (!containerRef.current) {
    return;
  }

  const iframe = containerRef.current.querySelector('iframe');

  if (!iframe) {
    return;
  }

  iframe.setAttribute('allow', 'autoplay; encrypted-media');
};

function subscribeToPlayerEvents(
  getPlayer: () => IFacebookPlayer,
  {
    onPlay,
    onPause,
    onFirstPlay,
    onFirstEnded,
    onEnded,
    onProgress,
    onError,
  }: Partial<IPlayerProps>,
  progress: IProgress,
  firstPlayEnded: React.MutableRefObject<boolean>,
  firstPlayStarted: React.MutableRefObject<boolean>,
  isPlayingNow: React.MutableRefObject<boolean>,
) {
  progress.subscribe(() => {
    onProgress?.(getPlayer().getCurrentPosition() || 0);
  });

  getPlayer().subscribe(EVENTS.PLAY, () => {
    if (!firstPlayStarted.current) {
      firstPlayStarted.current = true;
      onFirstPlay?.();
    }

    isPlayingNow.current = true;
    onPlay?.();

    progress.update();
  });

  getPlayer().subscribe(EVENTS.PAUSE, () => {
    isPlayingNow.current = false;
    onPause?.();

    progress.stop();
  });

  getPlayer().subscribe(EVENTS.END, () => {
    if (!firstPlayEnded.current) {
      firstPlayEnded.current = true;
      onFirstEnded?.();
    }

    isPlayingNow.current = false;
    onEnded?.();

    progress.stop();
  });

  if (onError) {
    getPlayer().subscribe(EVENTS.ERROR, onError);
  }
}

const useChangedPropsEffects = (
  { src, playing, muted, volume }: Partial<IPlayerProps>,
  firstPlayStarted: React.MutableRefObject<boolean>,
  firstPlayEnded: React.MutableRefObject<boolean>,
  waitForPlayer: () => Promise<IFacebookPlayer>,
) => {
  useChangedEffect(src, () => {
    firstPlayStarted.current = false;
    firstPlayEnded.current = false;
  });
  useChangedEffect(playing, () =>
    waitForPlayer().then(player => (playing ? player.play() : player.pause())),
  );
  useChangedEffect(muted, () =>
    waitForPlayer().then(player => (muted ? player.mute() : player.unmute())),
  );
  useChangedEffect(volume, () =>
    waitForPlayer().then(player => player.setVolume(volume! / 100)),
  );
};

const getHandles = (
  waitForPlayer: () => Promise<IFacebookPlayer>,
  getPlayer: () => IFacebookPlayer | void,
  isPlayingNow: React.MutableRefObject<boolean>,
): IPlayerHandles => {
  const handles: IPlayerHandles = {
    play: () => waitForPlayer().then(player => player.play()),
    pause: () => waitForPlayer().then(player => player.pause()),
    togglePlay: () => (isPlayingNow.current ? handles.pause() : handles.play()),
    getDuration: async () => {
      const player = getPlayer();
      return player ? player.getDuration() || 0 : 0;
    },
    getCurrentTime: async () => {
      const player = getPlayer();
      return player ? player.getCurrentPosition() || 0 : 0;
    },
    seekTo: time => waitForPlayer().then(player => player.seek(time)),
    getVolume: async () => {
      const player = getPlayer();
      return player ? player.getVolume() * 100 || 0 : 0;
    },
    setVolume: fraction =>
      waitForPlayer().then(player => player.setVolume(fraction / 100)),
    isMuted: async () => (await handles.getVolume()) === 0,
    isPlaying: () => isPlayingNow.current,
    mute: () => waitForPlayer().then(player => player.mute()),
    unMute: () => waitForPlayer().then(player => player.unmute()),
  };

  return handles;
};

const Player: IPlayer = (props, ref) => {
  const {
    src,
    playing,
    muted,
    volume = 0,
    containerDimensions,
    onInit,
    onProgress,
    controls,
    onError,
    onPlay,
    onPause,
    onFirstPlay,
    onFirstEnded,
    onEnded,
    isEditor,
    withFacebookDimensionsExperiment,
  } = props;
  const playerId = `facebook-${props.id}`;

  const isPlayingNow = React.useRef<boolean>(false);
  const containerWrapperRef = React.useRef<HTMLDivElement>(null);
  const containerRef = React.useRef<HTMLDivElement | null>(null);
  const firstPlayStarted = React.useRef<boolean>(false);
  const firstPlayEnded = React.useRef<boolean>(false);
  const progress = useProgress();

  const [localContainerDimensions, setLocalContainerDimensions] =
    React.useState({ width: '0', height: '0' });

  const updateLocalContainerDimensions = React.useCallback(() => {
    withFacebookDimensionsExperiment &&
      containerWrapperRef.current &&
      setLocalContainerDimensions({
        width: containerWrapperRef.current.offsetWidth.toString(),
        height: containerWrapperRef.current.offsetHeight.toString(),
      });
  }, [withFacebookDimensionsExperiment]);

  React.useEffect(() => {
    updateLocalContainerDimensions();
  }, [isEditor, updateLocalContainerDimensions]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const resizeCallback = React.useCallback(
    debounce(() => {
      isEditor && updateLocalContainerDimensions();
    }, RESIZE_DEBOUNCE_INTERVAL),
    [isEditor],
  );

  useResizeObserver({
    ref: containerWrapperRef,
    callback: resizeCallback,
  });

  React.useEffect(() => {
    getSDK<IFacebookSdk>(facebookSDK, sdkParams)
      .then(FB => {
        withFacebookDimensionsExperiment &&
          containerWrapperRef.current &&
          FB.XFBML.parse(containerWrapperRef.current);
      })
      .catch(e => {
        // TODO - handle errors properly
        throw e;
      });
  }, [localContainerDimensions, withFacebookDimensionsExperiment]);

  const [waitForPlayer, getPlayer] = usePlayer(containerRef, { playerId });

  useDidMount(() => {
    waitForPlayer()
      .then(player => {
        subscribeToPlayerEvents(
          () => getPlayer() as IFacebookPlayer,
          {
            onPlay,
            onPause,
            onFirstPlay,
            onFirstEnded,
            onEnded,
            onProgress,
            onError,
          },
          progress,
          firstPlayEnded,
          firstPlayStarted,
          isPlayingNow,
        );

        if (!muted) {
          player.unmute();
        }

        if (playing) {
          player.play();
        }

        onInit?.(player, 'facebook');
      })
      .catch(e => {
        // TODO - handle errors properly
        throw e;
      });
  });

  useChangedPropsEffects(
    { src, playing, muted, volume },
    firstPlayStarted,
    firstPlayEnded,
    waitForPlayer,
  );

  React.useImperativeHandle(ref, () =>
    getHandles(waitForPlayer, getPlayer, isPlayingNow),
  );

  return (
    <div ref={containerWrapperRef} className={classes.facebookContainer}>
      <div
        ref={containerRef}
        className="fb-video"
        data-player-name="Facebook"
        id={playerId}
        data-href={src}
        data-autoplay={playing ? 'true' : 'false'}
        data-allowfullscreen="true"
        data-controls={controls ? 'true' : 'false'}
        data-width={
          withFacebookDimensionsExperiment
            ? localContainerDimensions.width
            : containerDimensions.width
        }
        data-height={
          withFacebookDimensionsExperiment
            ? localContainerDimensions.height
            : containerDimensions.height
        }
        data-testid={TestIds.facebook}
      />
    </div>
  );
};

export default React.forwardRef(Player);
